<template>
	<DialogBase
		:dialogWidth="900"
		:dialogModel="dialogModel"
		@closeDialog="(val) => closeDialog(val)"
		scrollable
	>
		<template v-slot:title>Customer Details</template>

		<template v-for="(value, key) in getSelectedCustomerDetails()">
			<v-row v-if="key && value" no-gutters :key="key" class="my-6">
				<template v-if="key == 'account active'">
					<v-col cols="6">
						<div class="text-capitalize">{{ key }} : {{ value }}</div>
					</v-col>
					<v-col cols="6">
						<v-switch
							v-model="selectedModel.is_active"
							@change="toggleIsCustomerActive(selectedModel)"
							hide-details
							class="pa-0 ma-0"
							dense
							inset
						></v-switch>
					</v-col>
				</template>
				<template v-else-if="key == 'new account'">
					<v-col cols="6">
						<div class="text-capitalize">{{ key }} : {{ value }}</div>
					</v-col>
					<v-col cols="6">
						<v-switch
							v-model="selectedModel.customer.payload.is_new"
							@change="toggleIsNewCustomer(selectedModel)"
							hide-details
							class="pa-0 ma-0"
							dense
							inset
						></v-switch>
					</v-col>
				</template>
				<template v-else-if="key == 'Assign Agent'">
					<v-col cols="6">
						<div>
							{{ key }}
						</div>
					</v-col>
					<v-col cols="6">
						<assignAgentToCustomerBtn
							@refresh="refresh()"
							:customer="selectedModel.customer"
						/>
					</v-col>
				</template>
				<template v-else-if="key == 'Quotes Webhook Url'">
					<v-col cols="6">
						<div>
							{{ key }} :<br />
							<b>
								{{ value }}
							</b>
						</div>
					</v-col>
					<v-col cols="6">
						<addOrUpdateCustomerQuotesWebhookBtn
							@refresh="refresh()"
							:customer="selectedModel.customer"
						/>
					</v-col>
				</template>
				<template v-else-if="key == 'password'">
					<v-col cols="6">
						<div class="text-capitalize">{{ key }}</div>
					</v-col>
					<v-col cols="6">
						<changeUserAccountPassword
							@refresh="refresh()"
							:user="selectedModel"
							:hardRefreshAllowed="false"
						/>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="6">
						<div class="text-capitalize">
							{{ key }}
						</div>
					</v-col>
					<v-col cols="6">
						<div class="text-capitalize">
							<b>
								{{ value }}
							</b>
						</div>
					</v-col>
				</template>
			</v-row>
		</template>
		<v-divider></v-divider>
		<customerApiCredentialsManagement
			@refresh="refresh()"
			:user="selectedModel"
		/>

		<template v-slot:footer>
			<v-btn color="error" outlined block @click="closeDialog()">Close</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	import customerApiCredentialsManagement from "@/components/general/customerApiCredentialsManagement.vue";
	import addOrUpdateCustomerQuotesWebhookBtn from "@/components/general/addOrUpdateCustomerQuotesWebhookBtn.vue";
	import assignAgentToCustomerBtn from "@/components/general/assignAgentToCustomerBtn.vue";
	import changeUserAccountPassword from "@/components/general/changeUserAccountPassword.vue";
	export default {
		components: {
			DialogBase,
			FormMaker,
			customerApiCredentialsManagement,
			addOrUpdateCustomerQuotesWebhookBtn,
			assignAgentToCustomerBtn,
			changeUserAccountPassword,
		},
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
		},
		data() {
			return {};
		},
		mounted() {},
		computed: {},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh", val);
			},
			getSelectedCustomerDetails() {
				let user = this.selectedModel;
				let customer = this.selectedModel?.customer?.payload;
				return {
					"customer id": user?.customer?.customer_id,
					"first name": customer?.first_name,
					"last name": customer?.last_name,
					email: customer?.email,
					phone: customer?.phone_number,
					"business name": customer?.business_name,
					"business address": customer?.business_address,
					description: customer?.description,
					"account active": user?.is_active ? "Yes" : "No",
					"new account": user?.customer?.payload?.is_new ? "Yes" : "No",
					"Assign Agent": user.customer?.agent_id || "_",
					"Quotes Webhook Url": customer?.quotes_webhook_url || "addNow",
					password: "changeNow",
				};
			},
			async toggleIsCustomerActive(user) {
				return await this.post(
					`${this.baseUrl}/toggle-is-customer-active`,
					{ user_id: user.id },
					true
				).then((data) => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
					}
				});
			},
			async toggleIsNewCustomer(user) {
				return await this.post(
					`${this.baseUrl}/toggle-is-new-customer`,
					{ user_id: user.id },
					true
				).then((data) => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
					}
				});
			},
			async regenerateCustomerApiCredentials(user, isRegenerate = true) {
				if (isRegenerate)
					if (!confirm("After revoking. Existing credentials will not work."))
						return false;
				if (
					!confirm(
						`Are you sure you want to ${
							isRegenerate ? "Revoke" : "generate new"
						} api credentials.`
					)
				)
					return false;

				return await this.post(
					`${this.baseUrl}/regenerate-customer-api-credentials`,
					{ user_id: user.id },
					true
				).then((data) => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
					}
				});
			},
		},
	};
</script>
