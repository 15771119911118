<template>
	<v-row no-gutters>
		<v-col class="d-flex justify-center" cols="12">
			<v-btn
				color="secondary"
				@click="addOrUpdateCustomerQuoteWebhookDialog = true"
			>
				{{
					!!customer.payload.quotes_webhook_url
						? "Change Quotes Webhook Url"
						: "Add Quotes Webhook Url"
				}}
			</v-btn>
		</v-col>

		<v-dialog
			v-if="addOrUpdateCustomerQuoteWebhookDialog"
			v-model="addOrUpdateCustomerQuoteWebhookDialog"
			persistent
			max-width="600"
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					Quotes Webhook Url
					<v-spacer></v-spacer>
					<v-btn
						color="error"
						icon
						@click="addOrUpdateCustomerQuoteWebhookDialog = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="my-3">
					<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
						<v-text-field
							prepend-icon="mdi-web"
							v-model="quotes_webhook_url"
							clearable
							:type="'text'"
							:rules="rules"
						/>
					</v-form>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						color="green darken-1"
						outlined
						block
						@click="addOrUpdateCustomerQuoteWebhookUrl()"
					>
						Save Now
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	import { required, validUrl } from "@/plugins/validationRules.js";

	export default {
		components: {},
		props: {
			customer: { required: true, default: null }
		},
		data() {
			return {
				addOrUpdateCustomerQuoteWebhookDialog: false,
				quotes_webhook_url: "https://",
				isValidForm: false,
				rules: [validUrl()]
			};
		},
		mounted() {
			if (!!this.customer?.payload?.quotes_webhook_url) {
				this.quotes_webhook_url = this.customer?.payload?.quotes_webhook_url;
			}
		},
		computed: {},
		methods: {
			refresh() {
				this.$emit("refresh");
			},
			async addOrUpdateCustomerQuoteWebhookUrl() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					customer_id: this.customer.id,
					quotes_webhook_url: this.quotes_webhook_url
				};

				return await this.post(
					`${this.baseUrl}/add-or-update-customer-quotes-webhook-url`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.addOrUpdateCustomerQuoteWebhookDialog = false;
						this.refresh();
					}
				});
			}
		}
	};
</script>
